import React from 'react'
import logoCredGravatai from '../assets/imgs/logo.png'
import logosC from '../assets/imgs/logosc.png'
import '../assets/css/BcxSectionA.css'

const BcxSectionA = () => {
    return (
        <section id="SectionA">
            <div className="container">
                <div className="text">
                    <p>O CredGravataí é uma iniciativa que tem como principal objetivo oferecer linhas de 
                        crédito com condições diferenciadas, além de um acompanhamento especializado para garantir o uso consciente 
                        e eficaz dos recursos para micro, pequenas e médias empresas.</p>
                    <p>Este programa é uma parceria entre a Prefeitura de Gravataí e RS Garanti, com apoio do Sicoob Três Fronteiras. </p>
                    <p> O programa de crédito CredGravataí reflete o compromisso da Prefeitura de Gravataí com o 
                    desenvolvimento econômico e social do município, promovendo uma cultura de responsabilidade 
                    financeira e contribuindo para a melhoria da qualidade de vida de seus cidadãos. 
                    </p>
                </div>

                <div className="logos">
                    <img className="credGravatai" src={logoCredGravatai} alt="Logotipo da Cred Gravataí"/>
                    <img className="parceiros" src={logosC} alt="Logotipos Sicredi Sebrae Cresol RS Garanti Banco da familia Prefeitura de Caxias do Sul" />
                </div>
            </div>
        </section>
    )
}

export default BcxSectionA