import React from 'react'
import Sicredi from '../assets/imgs/logo-1.png'
import SMDET from '../assets/imgs/logo-2.png'
import RsGaranti from '../assets/imgs/logo-3.png'
import Sicob from '../assets/imgs/logo-4.png'

const CcxFooter = () => {
  return (
    <section id="footer">
      <div className="container">
        <div className="logos">
          <a href="https://gravatai.atende.net/" target={'_blank'}>
            {' '}
            <img src={Sicredi} alt="Logo da Prefeitura de gravataí" />
          </a>
          <a href="https://gravatai.atende.net/subportal/smdet-secretaria-municipal-de-desenvolvimento-economico-e-turismo" target={'_blank'}>
            {' '}
            <img src={SMDET} alt="Logo do SMDET" />
          </a>
          <a href="https://www.rsgaranti.org.br/" target={'_blank'}>
            {' '}
            <img src={RsGaranti} alt="Logo da RS Garanti" />
          </a>
          <a href="https://www.sicoob.com.br/" target={'_blank'}>
            {' '}
            <img src={Sicob} alt="Logo do Sicob" />
          </a>
        </div>
      </div>
    </section>
  )
}

export default CcxFooter
