import React from 'react'
import logoCredGravatai from '../assets/imgs/logo.png'
import '../assets/css/AcxHeader.css'


const AcxHeader = () => {
    return (

        <section id="header">
            <div className="container">
                <img src={logoCredGravatai} alt="Logotipo Cred Gravataí" />
                <div className='texto'>
                    <h1>
                        <strong>Melhor cidade
                            <br/>
                             para viver e
                            <br />investir
                        </strong>
                    </h1>
                </div>
            </div>
        </section>

    )
}

export default AcxHeader